import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Divider, IconButton, List, ListItem, Pagination, Tooltip
} from '@mui/material';
import PropTypes from 'prop-types';
import AddCircle from '@mui/icons-material/AddCircle';
import { useConfirm } from 'material-ui-confirm';
import PlatoonGroup from '../../../entities/platoons/PlatoonGroup/PlatoonGroup';
import EditButton from '../../../shared/ui/EditButton/EditButton';
import {
  useDeletePlatoonGroupMutation,
  useLazyGetPlatoonGroupsQuery
} from "../../../entities/platoons/platoonGroupsResource/redux/platoonGroupsResource.api";
import style from './CompanyPlatoonGroupsWidget.module.css';
import { getPlatoonGroupVehicles, getVehiclesWithDtoParams } from '../../../shared/api/api';
import convertDurationToSeconds from '../../../shared/utils/convertDurationToSeconds';
import NoData from '../../../shared/ui/NoData/NoData';
import DeleteButton from '../../../shared/ui/DeleteButton/DeleteButton';
import PendingData from '../../../shared/ui/PendingData/PendingData';
import { VEHICLE_GROUP_UPDATE_INTERVAL_MS } from '../../../entities/platoons/constants';

const pageSize = 5;

function CompanyPlatoonGroupsWidget({ company, toGroup, toCreateGroup }) {
  // eslint-disable-next-line no-undef
  const token = window.localStorage.getItem('token') || window.sessionStorage.getItem('token');

  // Количество страниц
  const [countPages, setCountPages] = useState(1);
  // Текущая страница
  const [page, setPage] = useState(1);
  // Отображаемые группы
  const [platoonGroups, setPlatoonGroups] = useState([]);
  const [displayedGroups, setDisplayedGroups] = useState(null);

  const [refreshData, setRefreshData] = useState(false);

  useEffect(() => {
    setDisplayedGroups(null);
  }, [company.companyId, page]);

  // Смена страницы
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const [
    getData,
    {
      data, isSuccess, isLoading, isError
    }
  ] = useLazyGetPlatoonGroupsQuery();

  const [deletePlatoonGroup] = useDeletePlatoonGroupMutation();
  const onDelete = useCallback(({ id }) => deletePlatoonGroup({
    id,
  }), [deletePlatoonGroup, token]);

  useEffect(() => {
    setCountPages(data ? Math.ceil(data.totalCount / pageSize) : 1);
  }, [data]);

  useEffect(() => {
    const payload = {
      params: {
        "companyId.equals": company.companyId,
        page: page - 1,
        size: pageSize,
        sort: 'startDateFrom,desc',
      }
    };
    getData(payload);
    const groupsDataInterval = setInterval(() => {
      getData(payload)
        .then(() => {
          setRefreshData((prev) => !prev);
        });
    }, VEHICLE_GROUP_UPDATE_INTERVAL_MS);
    return () => {
      clearInterval(groupsDataInterval);
    };
  }, [company?.companyId]);

  useEffect(() => {
    if (data && !isLoading) {
      if (isSuccess) {
        const groupVehiclesPromise = new Promise((resolve, reject) => {
          const newPlatoonGroups = [];
          if (data.platoonGroups.length) {
            data.platoonGroups.forEach((item, i) => {
              getPlatoonGroupVehicles(
                { groupId: item.platoonGroupId },
                { 'status.in': ['CONFIRM', 'JOINED'] }
              )
                .then((pgvs) => {
                  newPlatoonGroups[i] = {
                    id: item.platoonGroupId,
                    ...item,
                    platoonGroupVehicles: pgvs
                      .map((pgv) => ({
                        id: pgv.platoonGrpVehicleId,
                        ...pgv,
                        time: convertDurationToSeconds(pgv.intervalNext)
                                - convertDurationToSeconds(item.intervalDurationNoParsed),
                      })),
                  };
                  if (i === data.platoonGroups.length - 1) {
                    resolve(newPlatoonGroups);
                  }
                })
                .catch((error) => reject(error));
            });
          } else {
            resolve(newPlatoonGroups);
          }
        });
        groupVehiclesPromise
          .then((newData) => {
            setPlatoonGroups(newData);
          })
          .catch((error) => { throw error; });
      }
      if (isError) {
        setPlatoonGroups(null);
      }
    }
  }, [data, refreshData]);

  useEffect(() => {
    if (platoonGroups && !isLoading) {
      let vehiclesIds = {};
      platoonGroups.forEach((platoonGroup) => {
        platoonGroup.platoonGroupVehicles.forEach((platoonGroupVehicle) => {
          vehiclesIds = {
            ...vehiclesIds,
            [platoonGroupVehicle.vehicleId]: platoonGroupVehicle.vehicleId,
          };
        });
      });
      let params = `companyId.equals=${company.companyId}&`;
      Object.keys(vehiclesIds).forEach((vehicleId) => {
        params += `vehicleId.in=${vehicleId}&`;
      });
      getVehiclesWithDtoParams(params).then((vehicles) => {
        const newdisplayed = platoonGroups
          .map((platoonGroup) => {
            const finishVehicles = [];
            const noFinishVehicles = platoonGroup.platoonGroupVehicles.filter((pgv) => {
              const isZeroIntervalLeader = convertDurationToSeconds(pgv.intervalLeader) === 0;
              if (isZeroIntervalLeader) {
                finishVehicles.push({
                  ...pgv,
                  num: vehicles.find((vehicle) => (
                    vehicle.vehicleId === pgv.vehicleId
                  ))?.num,
                });
              }
              return !isZeroIntervalLeader;
            });
            const newNoFinishVehicles = noFinishVehicles
              .map((platoonGroupVehicle) => ({
                ...platoonGroupVehicle,
                num: vehicles.find((vehicle) => (
                  vehicle.vehicleId === platoonGroupVehicle.vehicleId
                ))?.num,
              }))
              .sort((a, b) => (
                convertDurationToSeconds(b.intervalLeader)
                - convertDurationToSeconds(a.intervalLeader)
              ));
            const firstPoint = !newNoFinishVehicles.length
              // eslint-disable-next-line no-undef
              ? [{ id: crypto.randomUUID(), nameGeoPoint: '', simplePoint: true, }]
              : [];
            return ({
              ...platoonGroup,
              noFinishVehicles: [
                ...firstPoint,
                ...newNoFinishVehicles,
                // eslint-disable-next-line no-undef
                { id: crypto.randomUUID(), nameGeoPoint: '', simplePoint: true, }
              ],
              finishVehicles
            });
          });
        setDisplayedGroups([...newdisplayed]);
      }).catch((error) => { throw error; });
    }
  }, [platoonGroups]);

  const confirm = useConfirm();

  return (
    <Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Tooltip title="Добавить">
          <IconButton
            color="primary"
            onClick={toCreateGroup}
          >
            <AddCircle />
          </IconButton>
        </Tooltip>
      </Box>
      {!displayedGroups
        ? <PendingData />
        : displayedGroups?.length
          ? (
            <List>
              {displayedGroups.map((platoonGroup) => (
                <ListItem
                  sx={{ display: 'flex', flexDirection: 'column' }}
                  key={platoonGroup?.id}
                >
                  <PlatoonGroup
                    platoonGroup={platoonGroup}
                    buttons={(
                      <>
                        <EditButton
                          onEdit={() => {
                            toGroup(platoonGroup.platoonGroupId);
                          }}
                        />
                        <DeleteButton
                          onDelete={() => {
                            confirm({
                              title: 'Удаление',
                              confirmationText: 'Да',
                              cancellationText: 'Отмена',
                              description: `Вы действительно хотите удалить «${platoonGroup?.name}»?`
                            })
                              .then(() => onDelete({ id: platoonGroup.platoonGroupId }))
                              .catch(() => { });
                          }}
                        />
                      </>
                  )}
                  />
                  <Divider flexItem />
                </ListItem>
              ))}
            </List>
          )
          : isError ? <NoData text="Произошла ошибка" /> : <NoData />}
      {countPages > 1
        ? (
          <Box className={style.pagination}>
            <Pagination
              count={countPages}
              page={page}
              onChange={handleChangePage}
            />
          </Box>
        )
        : null}
    </Box>
  );
}

CompanyPlatoonGroupsWidget.propTypes = {
  company: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ])),
  toGroup: PropTypes.func.isRequired,
  toCreateGroup: PropTypes.func.isRequired,
};

CompanyPlatoonGroupsWidget.defaultProps = {
  company: null,
};

export default CompanyPlatoonGroupsWidget;
